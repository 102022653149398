import React from "react";
import Layout from "../../components/Layout";
import ServiceRoll from "../../components/ServiceRoll";
import "../../styles/global.css";

export default class ServiceIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <div
          className="full-width-image-container margin-top-0"
          style={{
            backgroundImage: `url('/img/blog-index.jpg')`
          }}
        >
          <h2
            className="has-text-weight-bold is-size-1"
            style={{
              backgroundColor: "#212529",
              color: "#212529",
              padding: "1rem"
            }}
          >
            Services
          </h2>
        </div>
        <section className="section">
          <div className="container" style={{ padding: "4rem 0" }}>
            <h2>Services</h2>
            <ServiceRoll />
          </div>
        </section>
      </Layout>
    );
  }
}
